@keyframes spin1 {
  0% {
    transform: rotate(45deg) rotate3d(1, 1, 0, 0deg);
    z-index: 9;
  }
  50% {
    transform: rotate(45deg) rotate3d(1, 1, 0, 180deg);
    z-index: 9;
  }
  100% {
    transform: rotate(45deg) rotate3d(1, 1, 0, 355deg);
    z-index: 11;
  }
}
@keyframes spin2 {
  0% {
    transform: rotate(45deg) rotate3d(1, 1, 0, 0deg);
    z-index: 11;
  }
  50% {
    transform: rotate(45deg) rotate3d(1, 1, 0, 180deg);
    z-index: 9;
  }
  100% {
    transform: rotate(45deg) rotate3d(1, 1, 0, 355deg);
    z-index: 9;
  }
}
@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(5vh);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes mouse-appear {
  0% {
    opacity: 0;
    transform: translate(-50%, 5vh);
  }
  80% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
@keyframes mouse-disappear {
  0% {
    opacity: 1;
    transform: translateX(-50%);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -10vh);
  }
}
@keyframes bounce {
  0% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  10% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  40% {
    opacity: 1;
    transform: translate(-50%, 50%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
@keyframes revealLinks {
  0% {
    opacity: 0;
    transform: translateY(10vh);
  }
  80% {
    opacity: 1;
    transform: translateY(-3vh);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes dropIn {
  0% {
    opacity: 0;
    transform: translateY(-20vh);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: rgb(230, 255, 255);
  font-family: "Montserrat", sans-serif;
  cursor: default;
}

::-webkit-scrollbar {
  display: none;
}

body {
  background-color: rgb(26, 26, 26);
}

.scroll-down {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 70%;
  opacity: 0;
  animation: 1s ease-in-out 3s mouse-appear, infinite 3s ease 4s bounce;
}
.scroll-down.disappear {
  animation: 0.5s ease mouse-disappear;
}
.scroll-down .mouse {
  width: 35px;
  height: 35px;
}
.scroll-down .arrows {
  width: 15px;
  height: 15px;
  transform: translateY(-2px);
}

@media (max-width: 600px) {
  .logo-container {
    top: 10px;
    left: 20px;
  }
}
@media (min-width: 601px) {
  .logo-container {
    top: 1vw;
    left: 1vw;
  }
}
.logo-container {
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
}
.logo-container .logo {
  height: 40px;
  color: rgb(230, 255, 255);
  text-shadow: 0 0 4px rgb(26, 26, 26);
  z-index: 10;
  cursor: pointer;
}
.logo-container .logo-box-1,
.logo-container .logo-box-2 {
  width: 50px;
  height: 50px;
  align-self: center;
  justify-self: center;
  position: absolute;
  cursor: pointer;
}
.logo-container .logo-box-1 {
  border-top: 3px solid rgb(0, 167, 130);
  border-right: 3px solid rgb(0, 167, 130);
  animation: spin1 7s linear infinite;
}
.logo-container .logo-box-2 {
  border-left: 3px solid rgb(0, 167, 130);
  border-bottom: 3px solid rgb(0, 167, 130);
  animation: spin2 7s linear infinite;
}

.triangle_down {
  width: 100vw;
  height: 0;
  border-top: 20vh solid rgb(26, 26, 26);
  border-left: 30vw solid transparent;
  border-right: 70vw solid transparent;
  background-color: rgb(230, 255, 255);
}

.triangle_up {
  width: 100vw;
  height: 0;
  border-bottom: 20vh solid rgb(26, 26, 26);
  border-left: 70vw solid transparent;
  border-right: 30vw solid transparent;
  background-color: rgb(230, 255, 255);
}

/*****************/
/* INTRO SECTION */
/*****************/
.intro {
  width: 100vw;
  height: 70vh;
  position: relative;
}
.intro .intro_container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.intro .intro_container li,
.intro .intro_container h5 {
  cursor: default;
}
.intro .intro_container ul {
  list-style: none;
}
.intro .intro_container ul li {
  display: inline-block;
  background: linear-gradient(180deg, rgb(0, 167, 130) 40%, rgb(230, 255, 255) 100%);
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  letter-spacing: 1px;
  opacity: 0;
  font-size: clamp(40px, 10vw, 150px);
  animation: dropIn 0.2s ease-in-out forwards;
}
.intro .intro_container ul li:nth-child(1) {
  animation-delay: 0.4s;
}
.intro .intro_container ul li:nth-child(2) {
  animation-delay: 0.5s;
}
.intro .intro_container ul li:nth-child(3) {
  animation-delay: 0.6s;
}
.intro .intro_container ul li:nth-child(4) {
  animation-delay: 0.7s;
}
.intro .intro_container ul li:nth-child(5) {
  animation-delay: 0.8s;
}
.intro .intro_container ul li:nth-child(7) {
  animation-delay: 0.9s;
}
.intro .intro_container ul li:nth-child(8) {
  animation-delay: 1s;
}
.intro .intro_container ul li:nth-child(9) {
  animation-delay: 1.1s;
}
.intro .intro_container ul li:nth-child(10) {
  animation-delay: 1.2s;
}
.intro .intro_container ul li:nth-child(11) {
  animation-delay: 1.3s;
}
.intro .intro_container ul li:nth-child(12) {
  animation-delay: 1.4s;
}
.intro .intro_container ul li:nth-child(13) {
  animation-delay: 1.5s;
}
.intro .intro_container ul li:nth-child(14) {
  animation-delay: 1.6s;
}
.intro .intro_container h5 {
  letter-spacing: 2px;
  opacity: 0;
  font-size: clamp(8px, 3vw, 30px);
  animation: 0.7s ease-in-out 1.8s appear forwards;
}
.intro .intro_container .intro-buttons-container {
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
}
.intro .intro_container .intro-buttons-container .intro-resume,
.intro .intro_container .intro-buttons-container .intro-contact {
  width: clamp(90px, 12vw, 145px);
  height: clamp(30px, 5vw, 40px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
  margin: 0 clamp(10px, 2vw, 20px);
  font-size: clamp(12px, 1.7vw, 20px);
  border: 2px solid gray;
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;
  opacity: 0;
  color: gray;
  font-weight: 500;
  transition-duration: 0.3s;
  animation: 0.7s ease-in-out 2.1s appear forwards;
}
.intro .intro_container .intro-buttons-container .intro-resume:hover,
.intro .intro_container .intro-buttons-container .intro-contact:hover {
  transition: 0.5s ease;
  border: 2px solid rgb(0, 167, 130);
  color: rgb(0, 167, 130);
  background-color: black;
}

/*****************/
/* ABOUT SECTION */
/*****************/
.about {
  width: 100vw;
  height: 50vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgb(230, 255, 255);
  position: relative;
}
.about h1 {
  position: absolute;
  top: 45%;
  left: 5%;
  transform: translateX(-50%) rotate(-90deg);
  text-align: center;
  font-size: clamp(20px, 5vw, 50px);
  color: rgb(26, 26, 26);
}
.about .about_container {
  width: clamp(300px, 80vw, 1100px);
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  opacity: 0;
}
.about .about_container .img_container {
  width: clamp(100px, 13vw, 200px);
  height: auto;
  border-radius: 50%;
  align-self: center;
  overflow: hidden;
}
.about .about_container .img_container img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transform: scale(110%) translateY(5px);
}
.about .about_container .info_container {
  width: 70%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 20px;
}
.about .about_container .info_container p {
  font-size: clamp(20px, 4vw, 30px);
  font-weight: 700;
  color: rgb(26, 26, 26);
  margin-bottom: 10px;
}
.about .about_container .info_container p span {
  color: rgb(0, 167, 130);
  transition: 0.5s;
}
.about .about_container .info_container p:nth-child(2) {
  font-size: clamp(12px, 2vw, 20px);
  margin-bottom: 10px;
}
.about .about_container .info_container p:nth-child(3) {
  font-size: clamp(12px, 2vw, 20px);
}
.about .about_container .info_container .skills_container {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, clamp(20px, 4vw, 60px));
  -moz-column-gap: clamp(2px, 0.1vw, 5px);
       column-gap: clamp(2px, 0.1vw, 5px);
}
.about .about_container .info_container .skills_container img {
  width: clamp(20px, 4vw, 50px);
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
}

/****************/
/* WORK SECTION */
/****************/
.work {
  width: 100vw;
  height: auto;
  padding: 10vh 10vh;
  position: relative;
}
.work h1 {
  position: absolute;
  top: 45%;
  left: 5%;
  transform: translateX(-50%) rotate(-90deg);
  text-align: center;
  font-size: clamp(20px, 5vw, 50px);
}
.work .work_container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  opacity: 0;
}
.work .work_container .work_tile {
  width: clamp(250px, 50vw, 350px);
  height: clamp(250px, 50vw, 350px);
  display: grid;
  grid-template-rows: 20px 2fr 1fr 1fr;
  row-gap: clamp(0px, 1vw, 10px);
  justify-items: center;
  border: solid 2px rgb(0, 167, 130);
  border-radius: 50px;
  box-shadow: 0 0 20px rgb(0, 167, 130);
  padding: clamp(15px, 5vw, 30px);
  margin: 20px clamp(0px, 1vw, 20px);
}
.work .work_container .work_tile h3 {
  color: rgb(0, 167, 130);
  font-size: clamp(15px, 2vw, 20px);
}
.work .work_container .work_tile p {
  font-size: clamp(10px, 2vw, 15px);
}
.work .work_container .work_tile .work_chips {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.work .work_container .work_tile .work_chips div {
  padding: 3px;
  border: solid 1px rgb(230, 255, 255);
  border-radius: 5px;
  margin: clamp(0px, 0.5vw, 5px) clamp(0px, 0.5vw, 5px);
  font-size: clamp(10px, 2vw, 12px);
}
.work .work_container .work_tile .work_buttons {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  justify-self: flex-end;
}
.work .work_container .work_tile .work_buttons .work_github {
  width: clamp(25px, 5vw, 35px);
  height: clamp(25px, 5vw, 35px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 167, 130);
  border: none;
  border-radius: 5px;
  transition: 0.3s;
}
.work .work_container .work_tile .work_buttons .work_github:hover {
  box-shadow: 0 0 10px rgb(0, 167, 130);
  transition: 0.3s;
}
.work .work_container .work_tile .work_buttons .work_github img {
  width: clamp(20px, 3vw, 25px);
  height: clamp(20px, 3vw, 25px);
  -o-object-fit: contain;
     object-fit: contain;
  cursor: pointer;
}
.work .work_container .work_tile .work_buttons .work_link {
  width: -moz-fit-content;
  width: fit-content;
  height: clamp(25px, 5vw, 35px);
  padding: 0 clamp(5px, 1vw, 15px);
  background-color: rgb(0, 167, 130);
  border: none;
  border-radius: 5px;
  color: rgb(26, 26, 26);
  font-size: clamp(10px, 2vw, 15px);
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s;
}
.work .work_container .work_tile .work_buttons .work_link:hover {
  box-shadow: 0 0 10px rgb(0, 167, 130);
  transition: 0.3s;
}

/*******************/
/* CONTACT SECTION */
/*******************/
.contact {
  width: 100vw;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10vh 10vw;
  position: relative;
}
.contact .section_title {
  position: absolute;
  top: 45%;
  left: 5%;
  transform: translateX(-50%) rotate(-90deg);
  text-align: center;
  font-size: clamp(20px, 5vw, 50px);
  color: rgb(230, 255, 255);
}
.contact .contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: clamp(300px, 50vw, 500px);
}
.contact .contact-container .contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  gap: 40px;
}
.contact .contact-container .contact-form .hidden {
  opacity: 0;
  transform: translateX(-50vw);
}
.contact .contact-container .contact-form .reveal {
  opacity: 1;
  transform: none;
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
}
.contact .contact-container .contact-form .contact-title {
  color: rgb(0, 167, 130);
  font-size: 30px;
}
.contact .contact-container .contact-form .contact-title.reveal {
  transition-delay: 0s;
}
.contact .contact-container .contact-form .input {
  width: 100%;
  height: 25px;
  position: relative;
}
.contact .contact-container .contact-form .input.message {
  width: 100%;
  height: 60px;
}
.contact .contact-container .contact-form .input input, .contact .contact-container .contact-form .input textarea {
  background-color: transparent;
  cursor: text;
  border: none;
  border-bottom: solid 2px rgb(0, 167, 130);
  outline: none;
  color: rgb(0, 167, 130);
  font-size: 16px;
  font-weight: 500;
}
.contact .contact-container .contact-form .input input {
  width: 100%;
  height: 25px;
}
.contact .contact-container .contact-form .input textarea {
  width: 100%;
  height: 60px;
  resize: none;
}
.contact .contact-container .contact-form .input span {
  position: absolute;
  color: rgb(0, 167, 130);
  left: 0;
  bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  pointer-events: none;
  transition: 0.5s;
}
.contact .contact-container .contact-form .input input:focus ~ span, .contact .contact-container .contact-form .input input:valid ~ span {
  color: rgb(53, 53, 53);
  transform: translateY(-20px);
}
.contact .contact-container .contact-form .input textarea:focus ~ span, .contact .contact-container .contact-form .input textarea:valid ~ span {
  color: rgb(53, 53, 53);
  transform: translateY(-55px);
}
.contact .contact-container .contact-form .input.name.reveal {
  transition-delay: 0.2s;
}
.contact .contact-container .contact-form .input.email.reveal {
  transition-delay: 0.4s;
}
.contact .contact-container .contact-form .input.message.reveal {
  transition-delay: 0.6s;
}
.contact .contact-container .contact-form .submit button {
  width: 70px;
  height: 30px;
  border: 1px solid rgb(0, 167, 130);
  border-radius: 4px;
  cursor: pointer;
  font-weight: 700;
  background-color: rgb(26, 26, 26);
  color: rgb(0, 167, 130);
  transition: 0.3s ease;
}
.contact .contact-container .contact-form .submit button:hover {
  transition: 0.3s ease;
  background-color: rgb(0, 167, 130);
  color: black;
}
.contact .contact-container .contact-form .submit.reveal {
  transition-delay: 0.8s;
}
.contact .contact-container .contact-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5vh;
}
.contact .contact-container .contact-links img {
  width: clamp(30px, 10vw, 70px);
  height: clamp(30px, 10vw, 70px);
  margin: 0 clamp(10px, 2vw, 30px);
  cursor: pointer;
}
.contact .contact-container .contact-links img.hidden {
  opacity: 0;
  transform: translateY(10vh);
}
.contact .contact-container .contact-links .link-github.reveal {
  animation: 0.3s ease-in 1s forwards revealLinks;
}
.contact .contact-container .contact-links .link-linkedin.reveal {
  animation: 0.3s ease-in 1.2s forwards revealLinks;
}
.contact .contact-container .contact-links .link-resume.reveal {
  animation: 0.3s ease-in 1.4s forwards revealLinks;
}/*# sourceMappingURL=App.css.map */